// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3NkjkPhWvMiJiAsXltd6Vi {\n  float: right; }\n\n._3KelKgnCS30nANqj7ZdsBS {\n  background: unset !important;\n  padding: 0 !important; }\n\n._2HM4ks2Rkwf2Ai75k-ALG4 {\n  margin: 0 auto;\n  height: 32px;\n  width: 32px;\n  opacity: 0.8; }\n\n._2kA_KfbE-xOxtm25nkr7Nf {\n  display: inline-block; }\n\n._1CRGo3GFhZpFN1kCK2lzrj {\n  height: 44px !important;\n  width: 44px !important;\n  display: flex; }\n\n._33WgRiffBeW6AjM1pFC8Zg {\n  opacity: 0.1 !important; }\n\n._33WgRiffBeW6AjM1pFC8Zg:hover {\n  opacity: 0.2 !important; }\n", "",{"version":3,"sources":["C:/Users/Konrad Wojtysiak/skysnap_portal/Skysnap.WebPortal.Client/Login/sass/languageMenu.scss"],"names":[],"mappings":"AAAA;EACI,YAAY,EAAA;;AAGhB;EACI,4BAA4B;EAC5B,qBAAqB,EAAA;;AAGzB;EACI,cAAc;EACd,YAAY;EACZ,WAAW;EACX,YAAY,EAAA;;AAGhB;EACI,qBAAqB,EAAA;;AAGzB;EACI,uBAAuB;EACvB,sBAAuB;EACvB,aAAa,EAAA;;AAGjB;EACI,uBAAuB,EAAA;;AAG3B;EACI,uBAAuB,EAAA","file":"languageMenu.scss","sourcesContent":[".languageMenuContainer {\r\n    float: right;\r\n}\r\n\r\n.flagBtn {\r\n    background: unset !important;\r\n    padding: 0 !important;\r\n}\r\n\r\n.flag {\r\n    margin: 0 auto;\r\n    height: 32px;\r\n    width: 32px;\r\n    opacity: 0.8;\r\n}\r\n\r\n.flagOuter {\r\n    display: inline-block;\r\n}\r\n\r\n.flagWrapper {\r\n    height: 44px !important;\r\n    width:  44px !important;\r\n    display: flex;\r\n}\r\n\r\n.flagBg {\r\n    opacity: 0.1 !important;\r\n}\r\n\r\n.flagBg:hover {\r\n    opacity: 0.2 !important;\r\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageMenuContainer": "_3NkjkPhWvMiJiAsXltd6Vi",
	"flagBtn": "_3KelKgnCS30nANqj7ZdsBS",
	"flag": "_2HM4ks2Rkwf2Ai75k-ALG4",
	"flagOuter": "_2kA_KfbE-xOxtm25nkr7Nf",
	"flagWrapper": "_1CRGo3GFhZpFN1kCK2lzrj",
	"flagBg": "_33WgRiffBeW6AjM1pFC8Zg"
};
export default ___CSS_LOADER_EXPORT___;
