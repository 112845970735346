import React, { useEffect } from 'react'
import cx from 'classnames'
import { Grid } from 'semantic-ui-react'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import {
    setLocale,
    loadTranslations,
    syncTranslationWithStore,
} from "react-redux-i18n";
import thunkMiddleware from 'redux-thunk'

import { getCookie } from '../../Cookies/cookies'
import translations from '../translations/translations'
import LoginBox from './loginBox'
import { BackgroundVideo } from './backgroundVideo'
import { rootReducer } from '../reducers/reducers'

import loginStyles from '../sass/login.scss'
import commonStyles from '../sass/common.scss'

const store = createStore(rootReducer, applyMiddleware(
    thunkMiddleware,
));

function setLanguage() {
    let lang = getCookie('language');
    if (lang != '') {
        store.dispatch(setLocale(lang))
    }
    else
        store.dispatch(setLocale("pl"));
}

export default function Login(props) {
    useEffect(() => {
        syncTranslationWithStore(store);
        store.dispatch(loadTranslations(translations));

        setLanguage();
    }, [])

    return <Provider store={store}>
        <div className={loginStyles.loginContainer}>
            <BackgroundVideo />
            <Grid className={cx(commonStyles.containerFitted, commonStyles.noMargin, commonStyles.noPadding)}>
                <Grid.Row className={cx(commonStyles.containerFitted, commonStyles.noMargin, commonStyles.noPadding)}>
                    <Grid.Column width={5} className={cx(commonStyles.heightFitted, commonStyles.noMargin, commonStyles.noPadding)}>
                        <LoginBox />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </Provider>
}