// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._19Lo_-sPfyLiYjjEKLpMW0 {\n  z-index: -1;\n  position: absolute;\n  top: 0;\n  left: 10%; }\n", "",{"version":3,"sources":["C:/Users/Konrad Wojtysiak/skysnap_portal/Skysnap.WebPortal.Client/Login/sass/backgroundVideo.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,SAAS,EAAA","file":"backgroundVideo.scss","sourcesContent":[".backgroundVideo {\r\n    z-index: -1;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 10%;\r\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundVideo": "_19Lo_-sPfyLiYjjEKLpMW0"
};
export default ___CSS_LOADER_EXPORT___;
