import React from 'react';
import cx from 'classnames'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'

import { getTranslation } from '../translations/translations'
import { Logo } from './logo'
import LoginForm from './loginForm'
import LanguageMenu from './languageMenu'

import styles from '../sass/loginBox.scss'
import loginStyles from '../sass/login.scss'
import commonStyles from '../sass/common.scss'


export function LoginBox(props) {
    return <LoadingOverlay
        active={props.isLoading}
        spinner
        text={getTranslation(props.i18n, 'loading')}
        className={cx(commonStyles.containerFitted, loginStyles.loginBoxContainer)}
    >
        <div className={styles.loginBox}>
            <Logo />
            <h4 className="mb-0">
                <span className="d-block">{getTranslation(props.i18n, 'welcomeBack')},</span>
                <span>{getTranslation(props.i18n, 'pleaseSignIn')}.</span>
            </h4>
            <h6 className="mt-3">
                {getTranslation(props.i18n, 'noAccount')}?
            <a href="javascript:void(0);" className={cx(styles.signUpLink, "text-primary")}>
                    {getTranslation(props.i18n, 'signUpNow')}
                </a>
            </h6>
            <div className={commonStyles.invisibleDivider}></div>
            <LoginForm />
            <div className={commonStyles.invisibleDivider}></div>
            <LanguageMenu />
        </div>
    </LoadingOverlay>
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        isLoading: state.isLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginBox)