// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QVqEI00olyBBSpz-zwUkD {\n  height: 100%;\n  width: 100%; }\n\n._3Y_xEh9Fx_FGm1QvCoxaQN {\n  height: 100%; }\n\n._2msX2EHn2YzeCDSVZU5-OA {\n  padding: 0 !important; }\n\n.btJSZgd2FGzPe24c1mP9 {\n  margin: 0 !important; }\n\n._3Xe8qVEd9zU44htAn_1MQe {\n  margin-top: 1rem !important;\n  margin-bottom: 1rem !important;\n  height: 1px !important;\n  overflow: hidden !important;\n  background: #e9ecef !important; }\n\n._1F6oAQCOHN2sx07sPKMGOr {\n  margin-top: 1rem !important;\n  margin-bottom: 1rem !important;\n  height: 1px !important;\n  overflow: hidden !important;\n  background: #e9ecef !important;\n  opacity: 0; }\n", "",{"version":3,"sources":["C:/Users/Konrad Wojtysiak/skysnap_portal/Skysnap.WebPortal.Client/Login/sass/common.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,WAAW,EAAA;;AAGf;EACI,YAAY,EAAA;;AAGhB;EACI,qBAAqB,EAAA;;AAGzB;EACI,oBAAoB,EAAA;;AAGxB;EACI,2BAA2B;EAC3B,8BAA8B;EAC9B,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B,EAAA;;AAGlC;EACI,2BAA2B;EAC3B,8BAA8B;EAC9B,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;EAC9B,UAAU,EAAA","file":"common.scss","sourcesContent":[".containerFitted {\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n.heightFitted {\r\n    height: 100%;\r\n}\r\n\r\n.noMargin {\r\n    padding: 0 !important;\r\n}\r\n\r\n.noPadding {\r\n    margin: 0 !important;\r\n}\r\n\r\n.divider {\r\n    margin-top: 1rem !important;\r\n    margin-bottom: 1rem !important;\r\n    height: 1px !important;\r\n    overflow: hidden !important;\r\n    background: #e9ecef !important;\r\n}\r\n\r\n.invisibleDivider {\r\n    margin-top: 1rem !important;\r\n    margin-bottom: 1rem !important;\r\n    height: 1px !important;\r\n    overflow: hidden !important;\r\n    background: #e9ecef !important;\r\n    opacity: 0;\r\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFitted": "QVqEI00olyBBSpz-zwUkD",
	"heightFitted": "_3Y_xEh9Fx_FGm1QvCoxaQN",
	"noMargin": "_2msX2EHn2YzeCDSVZU5-OA",
	"noPadding": "btJSZgd2FGzPe24c1mP9",
	"divider": "_3Xe8qVEd9zU44htAn_1MQe",
	"invisibleDivider": "_1F6oAQCOHN2sx07sPKMGOr"
};
export default ___CSS_LOADER_EXPORT___;
