// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._22mlVDzJiw6FbpJjXYM1VX {\n  position: absolute;\n  top: 0px;\n  bottom: 0;\n  width: 100%;\n  left: 0;\n  overflow: hidden; }\n\n._2DcS-6feeCpmsPr1vy5253 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white; }\n", "",{"version":3,"sources":["C:/Users/Konrad Wojtysiak/skysnap_portal/Skysnap.WebPortal.Client/Login/sass/login.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,OAAO;EACP,gBAAgB,EAAA;;AAGpB;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB,EAAA","file":"login.scss","sourcesContent":[".loginContainer {\r\n    position: absolute;\r\n    top: 0px;\r\n    bottom: 0;\r\n    width: 100%;\r\n    left: 0;\r\n    overflow: hidden;\r\n}\r\n\r\n.loginBoxContainer {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: white;\r\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": "_22mlVDzJiw6FbpJjXYM1VX",
	"loginBoxContainer": "_2DcS-6feeCpmsPr1vy5253"
};
export default ___CSS_LOADER_EXPORT___;
