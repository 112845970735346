import React from 'react'

import styles from '../sass/backgroundVideo.scss'

import vid from '../images/login-1.mp4'
import vidImg from '../images/login-1.png'

export function BackgroundVideo(props) {
    return <video className={styles.backgroundVideo} poster={vidImg} autoPlay muted loop>
        <source src={vid} type="video/mp4" />
    </video>
}