import { combineReducers } from 'redux'
import { i18nReducer } from "react-redux-i18n";

import { ON_AUTHENTICATION, ON_LOAD_START } from '../actions/actions'

export function isLoading(state=false, action) {
    switch (action.type) {
        case ON_LOAD_START:
            return true;
        case ON_AUTHENTICATION:
            return false;
        default:
            return state;
    }
}

export function isAuthenticated(state=null, action) {
    switch (action.type) {
        case ON_AUTHENTICATION:
            return action.authenticated;
        default:
            return state;
    }
}

export const rootReducer = combineReducers({isAuthenticated, isLoading, i18n: i18nReducer});