import React, { useState } from 'react'
import { Form, Checkbox } from 'semantic-ui-react'
import { connect } from 'react-redux'
import cx from 'classnames'

import { getTranslation } from '../translations/translations'
import { authenticate } from '../actions/actions'

import styles from '../sass/loginForm.scss'
import commonStyles from '../sass/common.scss'

function LoginForm(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return <React.Fragment>
        <Form>
            <Form.Group widths='equal'>
                <Form.Input
                    fluid label={getTranslation(props.i18n, 'email')}
                    placeholder={getTranslation(props.i18n, 'emailHere')}
                    value={email}
                    onChange={(e, data) => {
                        setEmail(e.target.value);
                    }} />
                <Form.Input
                    fluid label={getTranslation(props.i18n, 'password')}
                    placeholder={getTranslation(props.i18n, 'passwordHere')}
                    value={password}
                    type="password"
                    onChange={(e, data) => {
                        setPassword(e.target.value);
                    }}
                />
            </Form.Group>
            {
                props.authenticationError && <div className={commonStyles.widthFitted}>
                    <div className={cx(commonStyles.widthFitted, styles.errorText)}>
                        {getTranslation(props.i18n, 'incorrectLoginData')}
                    </div>
                    <div className={commonStyles.invisibleDivider}></div>
                </div>
            }
            <Form.Field
                control={Checkbox}
                label={{ children: getTranslation(props.i18n, 'keepMeSignedIn') }}
            />
        </Form>
        <div className={commonStyles.invisibleDivider}></div>
        <div className={styles.loginBtnContainer}>
            <a href="javascript:void(0);" className="btn-lg btn btn-link">{getTranslation(props.i18n, 'recoverPassword')}</a>
            <button
                class="btn btn-primary btn-lg"
                onClick={
                    () => {
                        props.onLogin(email, password);
                    }
                }
            >{getTranslation(props.i18n, 'logIn')}</button>
        </div>
    </React.Fragment>
}

function mapStateToProps(state) {
    return {
        authenticationError: state.isAuthenticated != null && !state.isAuthenticated,
        i18n: state.i18n
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLogin: (email, password) => { dispatch(authenticate(email, password)); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);