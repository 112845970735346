import React from 'react'
import { Button } from 'semantic-ui-react'
import Flag from "react-flagkit";
import { supportedLanguageList } from '../../Language/language'
import { connect } from 'react-redux'
import cx from 'classnames'

import { changeLanguage } from '../actions/actions'

import styles from '../sass/languageMenu.scss'
import commonStyles from '../sass/common.scss'

function mapLocaleToFlag(locale) {
    switch (locale) {
        case 'pl':
            return 'PL';
        case 'de':
            return 'DE';
        case 'en':
            return 'GB'
    }
}


export function LanguageMenu(props) {
    return <React.Fragment>
        <div className={commonStyles.widthFitted}>
            <div className={styles.languageMenuContainer}>
                {
                    supportedLanguageList().map((language) => {
                        return (<Button className={styles.flagBtn}
                            onClick={
                                () => {
                                    props.changeLocale(language.locale);
                                }
                            }
                        >
                            <span className={cx("icon-wrapper icon-wrapper-alt rounded-circle", styles.flagWrapper)}><span className={cx("icon-wrapper-bg bg-focus", styles.flagBg)}></span><Flag className={cx(styles.flag, "rounded-circle")} country={mapLocaleToFlag(language.locale)} /></span>
                        </Button>)
                    })
                }
            </div>
        </div>
    </React.Fragment>
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeLocale: (locale) => dispatch(changeLanguage(locale))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageMenu);