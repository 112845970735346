import fetch from 'cross-fetch'
import { setLocale } from "react-redux-i18n";
import { setCookie } from '../../Cookies/cookies' 

export const ON_LOAD_START = "ON_LOAD_START";
export const ON_AUTHENTICATION = "ON_AUTHENTICATION";


export function onAuthenticate(authenticated) {
    return {
        type: ON_AUTHENTICATION,
        authenticated: authenticated
    }
}

export function changeLanguage(locale) {
    setCookie('language', locale);
    return setLocale(locale);
}

export function onAuthenticationResponseReceived(dispatch, response) {
    if (!response)
        dispatch(onAuthenticate(false));
    else {
        setCookie("token", response, 0.5);
        window.location = "/";
        dispatch(onAuthenticate(true));
    }
}

function onLoadStart() {
    return {
        type: ON_LOAD_START
    };
}

export function authenticate(email, password) {
    return function (dispatch) {
        dispatch(onLoadStart());
        return fetch(`../api/Auth`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(response => {console.log(response); return response.json()} )
        .then(json => { console.log(json); onAuthenticationResponseReceived(dispatch, json.token) }
        )
    }
}