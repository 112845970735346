// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3UhI3DbSNRQLNGg2QpWqqZ {\n  width: 80%; }\n\n._2UR_nmY2BCkX4-KCY7FyMr {\n  margin-left: 5px; }\n", "",{"version":3,"sources":["C:/Users/Konrad Wojtysiak/skysnap_portal/Skysnap.WebPortal.Client/Login/sass/loginBox.scss"],"names":[],"mappings":"AAAA;EACI,UAAU,EAAA;;AAGd;EACI,gBAAgB,EAAA","file":"loginBox.scss","sourcesContent":[".loginBox {\r\n    width: 80%;\r\n}\r\n\r\n.signUpLink {\r\n    margin-left: 5px;\r\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBox": "_3UhI3DbSNRQLNGg2QpWqqZ",
	"signUpLink": "_2UR_nmY2BCkX4-KCY7FyMr"
};
export default ___CSS_LOADER_EXPORT___;
