// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2kcRu9U_KuS575jyDoewBO {\n  height: 23px;\n  width: 100%; }\n", "",{"version":3,"sources":["C:/Users/Konrad Wojtysiak/skysnap_portal/Skysnap.WebPortal.Client/Login/sass/logo.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,WAAW,EAAA","file":"logo.scss","sourcesContent":[".logo {\r\n    height: 23px;\r\n    width: 100%;\r\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "_2kcRu9U_KuS575jyDoewBO"
};
export default ___CSS_LOADER_EXPORT___;
