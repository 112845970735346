import React from 'react'

import styles from '../sass/logo.scss'

import logoImg from '../images/logo.png'

export function Logo(props) {
    return <div className={styles.logo} style={{
        background: `url(${logoImg}) no-repeat`
    }}>
    </div>
}