// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2cKgyjNFRobL6MVAM_Zp5f {\n  width: 100%;\n  text-align: right; }\n\n._1957OkJ6iW_iDSdt10Le1y {\n  color: red; }\n", "",{"version":3,"sources":["C:/Users/Konrad Wojtysiak/skysnap_portal/Skysnap.WebPortal.Client/Login/sass/loginForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,iBAAiB,EAAA;;AAGrB;EACI,UAAU,EAAA","file":"loginForm.scss","sourcesContent":[".loginBtnContainer {\r\n    width: 100%;\r\n    text-align: right;\r\n}\r\n\r\n.errorText {\r\n    color: red;\r\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBtnContainer": "_2cKgyjNFRobL6MVAM_Zp5f",
	"errorText": "_1957OkJ6iW_iDSdt10Le1y"
};
export default ___CSS_LOADER_EXPORT___;
